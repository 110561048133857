import React, {createContext, useContext, useReducer} from "react";


export const AuthContext = createContext("");
export const AuthDispatchContext = createContext((action: {type: string, role?: string})=> {});

function authReducer(auth, action) {
    switch (action.type) {
        case 'success':
            return action.role
        case 'fail':
            return ''
    }
}

export function AuthProvider({children}) {
const [auth, dispatch] = useReducer(authReducer, "")

    return (
        <AuthContext.Provider value={auth}>
            <AuthDispatchContext.Provider value={dispatch}>
                {children}
            </AuthDispatchContext.Provider>
        </AuthContext.Provider>
    )
}

export function useAuth() {
    return useContext(AuthContext)
}

export function useAuthDispatch() {
    return useContext(AuthDispatchContext)
}
