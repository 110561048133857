import React, {FC, lazy, Suspense, useState} from "react";
import {Grid, Paper} from "@mui/material";
import AppMenu from "./AppMenu";
import Loading from "./Loading";
import FlybysView from "./FlybysView";
import ActivityInfoView from "./ActivityInfoView";
import DetectionsView from "./DetectionsView";

const AppView: FC = () => {
    const [view, setView] = useState('flybys')

    const StatsView = lazy(() => import("./StatsView"));

    return (
        <Grid container spacing={1} height={1} overflow={'hidden'}>
            <Grid item xs={2} height={1}>
                <Paper sx={{height: 0.99}}>
                    <AppMenu onSelect={setView}/>
                </Paper>
            </Grid>
            <Grid item xs={10} height={1}>
                <Paper sx={{height: 0.99}}>
                    <Suspense fallback={<Loading/>}>
                        {
                            (view === 'flybys' && <FlybysView/>)
                            ||
                            (view === 'activity' && <ActivityInfoView/>)
                            ||
                            (view === 'detections' && <DetectionsView/>)
                            ||
                            (view === 'stats' && <StatsView/>)
                            ||
                            <></>
                        }
                    </Suspense>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default AppView