import React, {FC} from "react";
import {useAuth} from "./AuthContext";
import LoginView from "./LoginView";
import AppView from "./AppView";
import {WatcherProvider} from "./WatcherContext";

const MainView: FC = () => {
    const auth = useAuth()
    console.log(`auth: ${auth}`)

    return (
        auth ?
            <WatcherProvider>
                <AppView/>
            </WatcherProvider>
            :
            <LoginView/>
    )
}

export default MainView;
