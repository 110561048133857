import React, {FC, useEffect, useState} from "react";
import {
    Box,
    Chip,
    Grid,
    ListItem,
    MenuItem,
    MenuList,
    Select,
    SelectChangeEvent,
    Skeleton,
    Stack,
    Typography
} from "@mui/material";
import {Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis} from "recharts";
import Measure from "react-measure";
import {ActivityInfo, Flyby} from "./types";
import {Circle} from "@mui/icons-material";
import dayjs from "dayjs";
import {useAuthDispatch} from "./AuthContext";

const ActivityInfoView: FC = () => {

    const [currentData, setCurrentData] = useState<ActivityInfo>()
    const [forDaysData, setForDaysData] = useState<ActivityInfo>()
    const [daysActivityFor, setDaysActivityFor] = useState("3")

    const [blinkStatus, setBlinkStatus] = useState<"success" | "error">(null);
    const [flybys, setFlybys] = useState<Flyby[]>([])

    const authDispatch = useAuthDispatch();

    const [graphWidth, setGraphWidth] = useState();

    useEffect(() => {
        fetch(`/api/activity/current`, {
            credentials: 'include',
        })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    authDispatch({type: 'fail'})
                }
            })
            .then(data => {
                setCurrentData(data);
            })
            .catch(reason => {
                console.log(`api error: ${reason}`)
            })
        const timer = setInterval(() => {
            fetch(`/api/activity/current`)
                .then(res => {
                    if (res.ok) {
                        return res.json()
                    }
                })
                .then(data => {
                    setCurrentData(data);
                })
                .catch(reason => {
                    console.log(`api error: ${reason}`)
                })
        }, 30000)
        return () => {
            clearInterval(timer);
        }
    }, []);

    useEffect(() => {
        fetch(`/api/activity/fordays?days=${daysActivityFor}`)
            .then(res => {
                if (res.ok) {
                    return res.json()
                }
            })
            .then(data => {
                setForDaysData(data);
            })
            .catch(reason => {
                console.log(`api error: ${reason}`)
            })
        const timer = setInterval(() => {
            setBlinkStatus(null)
            fetch(`/api/activity/fordays?days=${daysActivityFor}`)
                .then(res => {
                    if (res.ok) {
                        return res.json()
                    }
                })
                .then(data => {
                    setForDaysData(data)
                    setBlinkStatus("success")
                    setTimeout(() => setBlinkStatus(null), 300)
                })
                .catch(reason => {
                    console.log(`api error: ${reason}`)
                    setBlinkStatus("error")
                    setTimeout(() => setBlinkStatus(null), 300)
                })
        }, 30000)
        return () => {
            clearInterval(timer)
        }
    }, [daysActivityFor]);

    useEffect(() => {
        setBlinkStatus(null)
        fetch(`/api/flyby`)
            .then(res => {
                if (res.ok) {
                    return res.json()
                }
            })
            .then(data => {
                setFlybys(data);
                setBlinkStatus("success")
                setTimeout(() => setBlinkStatus(null), 300)
            })
            .catch(reason => {
                console.log(`api error: ${reason}`)
                setBlinkStatus("error")
                setTimeout(() => setBlinkStatus(null), 300)
            })
        const timer = setInterval(() => {
            setBlinkStatus(null)
            fetch(`/api/flyby`)
                .then(res => {
                    if (res.ok) {
                        return res.json()
                    }
                })
                .then(data => {
                    setFlybys(data)
                    setBlinkStatus("success")
                    setTimeout(() => setBlinkStatus(null), 300)
                })
                .catch(reason => {
                    console.log(`api error: ${reason}`)
                    setBlinkStatus("error")
                    setTimeout(() => setBlinkStatus(null), 300)
                })
        }, 5000)
        return () => {
            clearInterval(timer)
        }
    }, [])

    const handleDaysSelect = (event: SelectChangeEvent) => {
        setDaysActivityFor(event.target.value);
    }

    const isNewFlyby = (flyby: Flyby) => {
        return (new Date().getTime() - new Date(flyby.dateTimeTo).getTime()) < 30_000
    }

    const formatDates = (flyby: Flyby) => {
        const dateFrom = dayjs(flyby.dateTimeFrom)
        const dateTo = dayjs(flyby.dateTimeTo)
        if (flyby.powers.length == 1) {
            return dateFrom.format('HH:mm');
        }
        const diffHours = dateTo.diff(dateFrom, 'hour')
        const diffMinutes = dateTo.diff(dateFrom, 'minute') % 60
        const diffSeconds = dateTo.diff(dateFrom, 'second') % 60
        const diff = `${diffHours.toString().padStart(2, "0")}:${diffMinutes.toString().padStart(2, "0")}:${diffSeconds.toString().padStart(2, "0")}`
        return `${dateFrom.format('HH:mm')} (${diff})`
    }

    return (
        <Box height={1} overflow={'hidden'}>
            <Stack borderBottom={1} p={1} borderColor={'divider'} spacing={4} flexDirection={"row"}
                   alignItems={'baseline'}>
                <Typography variant={"h6"}>Активность БПЛА
                    {blinkStatus && <Circle sx={{ml: '5px'}} fontSize={'small'} color={blinkStatus}/>}</Typography>
            </Stack>

            <Grid container spacing={1} height={0.85}>
                <Grid item xs={6} height={1}>
                    <Stack borderBottom={1} p={1} borderColor={'divider'} spacing={1}>
                        <Typography variant={"h6"}>Текущая активность</Typography>
                        {currentData &&
                            <BarChart data={currentData.data}
                                      height={300} width={graphWidth}>
                                <CartesianGrid/>


                                <XAxis key={'hour'}/>
                                <YAxis key={'activityDji'}/>
                                <Tooltip/>
                                <Legend/>
                                <Bar dataKey={'activityDji'} label={'DJI'} fill={'#405dd1'}/>
                                <Bar dataKey={'activityFpv'} label={'FPV'} fill={'#69c969'}/>
                            </BarChart>
                        }
                        {!currentData &&
                            <Skeleton height={300}/>
                        }

                    </Stack>
                    <Stack borderBottom={1} p={1} borderColor={'divider'} spacing={1}>
                        <Stack direction={'row'} spacing={2} alignItems={'baseline'}>
                            <Typography variant={"h6"}>Средняя активность за:</Typography>
                            <Select
                                value={daysActivityFor}
                                onChange={handleDaysSelect}>
                                <MenuItem value={"1"}>1 день</MenuItem>
                                <MenuItem value={"3"}>3 дня</MenuItem>
                                <MenuItem value={"7"}>неделю</MenuItem>
                            </Select>
                        </Stack>
                        {forDaysData &&
                            <Measure bounds
                                     onResize={contentRect => setGraphWidth(contentRect.bounds.width)}>
                                {({measureRef}) => (
                                    <Box ref={measureRef}>
                                        <BarChart data={forDaysData.data}
                                                  height={300} width={graphWidth}>
                                            <CartesianGrid/>

                                            <XAxis key={'hour'}/>
                                            <YAxis key={'activityDji'}/>
                                            <Tooltip/>
                                            <Legend/>
                                            <Bar dataKey={'activityDji'} fill={'#405dd1'}/>
                                            <Bar dataKey={'activityFpv'} fill={'#69c969'}/>
                                        </BarChart>
                                    </Box>
                                )}
                            </Measure>
                        }
                        {!forDaysData &&
                            <Skeleton height={300}/>
                        }
                    </Stack>
                </Grid>
                <Grid item xs={6} height={650}>
                    <Typography variant={"h6"}>Пролёты БПЛА</Typography>
                    <Box borderBottom={1} p={1} borderColor={'divider'}>
                        <Grid container>
                            <Grid item xs={4}>
                                <Typography>Время</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography>Тип</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>Частоты</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>Наименования</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <MenuList sx={{height: 0.9, overflowY: 'auto'}}>
                        {
                            flybys.map((flyby, ind, array) => (
                                <MenuItem
                                    key={flyby.id}
                                    divider
                                    disableRipple
                                    sx={{
                                        padding: 0,
                                        backgroundColor: isNewFlyby(flyby) ? 'lightgreen' : 'inherit'
                                    }}>
                                    <ListItem>
                                        <Grid container padding={0}>
                                            <Grid item xs={4} overflow={'hidden'}>
                                                <Typography>{formatDates(flyby)}</Typography>
                                            </Grid>
                                            <Grid item xs={1} overflow={'hidden'}>
                                                <Typography>{flyby.type}</Typography>
                                            </Grid>
                                            <Grid item xs={3} overflow={'hidden'}>
                                                <Stack spacing={1}
                                                       direction={'row'}>{flyby.freqs.map((freq) => <Chip
                                                    label={freq}
                                                    size={'small'}/>)}</Stack>
                                            </Grid>
                                            <Grid item xs={4} overflow={'hidden'}>
                                                <Stack spacing={1}
                                                       direction={'row'}>{flyby.names.map((name) => <Chip
                                                    label={name} size={'small'}/>)}</Stack>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                </MenuItem>
                            ))
                        }
                    </MenuList>

                </Grid>
            </Grid>
        </Box>
    )
}

export default ActivityInfoView;
